// imports the class that provides local storage for token
import { HttpInterceptorFn } from '@angular/common/http';
export const AuthInterceptor:HttpInterceptorFn=(req,next)=>{
  // send cloned request with header to the next handler.
  return next(req);
};





// @Injectable({
//   providedIn: 'root'
// })

// export class AuthInterceptorService implements HttpInterceptor {
//   constructor(public cs: CommonService) {

//   }
//   intercept(req: HttpRequest<any>, next: HttpHandler) {
//     const token: any = this.cs.userSession.akey; // This retrieves a token from local storage
//     req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });// This clones HttpRequest and Authorization header with Bearer token added
//     req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
//     req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
//     return next.handle(req)
//       .pipe(
//         catchError((error: HttpErrorResponse) => {
//           // Catching Error Stage
//           if (error && error.status === 401) {
//           }
//           const err = error.error.message || error.statusText;
//           return throwError(error); // any further errors are returned to frontend
//         })
//       );
//   }
// }
