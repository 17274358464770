import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { CommonService } from '../shared/common.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { trigger, transition, animate, style } from '@angular/animations';
import { PrintPageComponent } from './print/print.component'
const itemInvisibleStyle = style({ height: 0, overflow: 'hidden' });
const itemVisibleStyle = style({ height: '*', overflow: 'hidden' });
import { SwPush, SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { provideUserIdleConfig } from 'angular-user-idle';
import { QuillModule } from 'ngx-quill';
import { UserIdleService } from 'angular-user-idle';
import { SocketTsService } from '../shared/socket.service';
export const ENTER_LEAVE_ANIMATION = trigger('enterLeaveAnimation', [
  transition(':enter', [

    itemInvisibleStyle,
    animate('500ms ease-out', itemVisibleStyle),
  ]),
  transition(':leave', [
    // query('.child', style({padding: '0px'}), { optional: true }),
    itemVisibleStyle,
    animate('500ms ease-in', itemInvisibleStyle),

  ]),
]);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/');
}
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterOutlet, PrintPageComponent, QuillModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CommonService,SocketTsService],
  animations: [ENTER_LEAVE_ANIMATION]
})
export class AppComponent implements OnInit {
  static isBrowser: any = new BehaviorSubject<boolean>(false);
  constructor( public socketService: SocketTsService,private swUpdate: SwUpdate, private swPush: SwPush, @Inject(PLATFORM_ID) platformId: any, public cs: CommonService, private overlayContainer: OverlayContainer, private userIdle: UserIdleService) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    // translate.addLangs(['en', 'fr']);
    // translate.setDefaultLang('en');
    // let browserLang: any = translate.getBrowserLang();
    // let ex :any=(browserLang.match(/en|fr/) ? browserLang : 'en');
    // translate.use(ex);


    // let pwd= `${environment['PASSWORD']}`;
  }

  @HostListener('keyup', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.userIdle.resetTimer();
  }


  @HostListener('mouseup', ['$event'])
  @HostListener('mousemove', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.userIdle.resetTimer();
  }
  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      const updatesAvailable = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })));
      if (updatesAvailable) {
        // if (confirm("New version available. Load New Version?")) {
        //   window.location.reload();
        // }
      }
    }
  }
  title = 'smatoapps';

}
