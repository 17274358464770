import { Component, OnInit } from '@angular/core';
XMLHttpRequest
@Component({
  selector: 'block-print',
  standalone:true,
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintPageComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
